/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import type DataRequest from '~/src/Domain/DataRequest/DataRequest'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useDataRequestorState from '~/src/UserInterface/DataRequest/composables/useDataRequestorState'

/**
 * This middleware must be called on data requestor pages
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated as a data request recipient
 *
 * This middleware checks if there is a 'data_request' cookie value. If available it fetches
 * the data request details and populates state.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[DATA_REQUEST] Middleware fired')

  if (
    typeof to.params.dataRequestRecipientId !== 'string'
    || typeof to.params.dataRequestId !== 'string'
  ) {
    logger.error(
      '[DATA_REQUEST] Route does not have the correct url parameters. Redirecting to error page.',
    )

    // eslint-disable-next-line ts/no-unsafe-argument
    return navigateTo(useNuxtApp().$localePath('dataRequest-Error'))
  }

  const { isAuthenticated, hasDataRequest } = useDataRequestorState()
  if (isAuthenticated.value && hasDataRequest.value) {
    return
  }

  const dataRequestSecurity = useService('dataRequestSecurity')
  if (!isAuthenticated.value) {
    try {
      await dataRequestSecurity.refreshJwtToken()
    } catch {
      // eslint-disable-next-line ts/no-unsafe-argument
      await navigateTo(useNuxtApp().$localePath('dataRequest-Error'))
    }
  }

  if (!hasDataRequest.value) {
    const uuidEncoder = useService('uuidEncoder')
    const repository = useService('dataRequestorApiRepository')

    let dataRequest: DataRequest
    try {
      dataRequest = await repository.get(
        uuidEncoder.decode(to.params.dataRequestRecipientId),
        uuidEncoder.decode(to.params.dataRequestId),
      )
    } catch {
      // eslint-disable-next-line ts/no-unsafe-argument
      await navigateTo(useNuxtApp().$localePath('dataRequest-Error'))
      return
    }

    const store = useService('dataRequestorStoreRepository')
    store.markState(dataRequest)
  }
})
